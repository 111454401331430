import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs
} from '@mui/material';
import BDDGenerationForm from 'src/components/init-bdd/BDDGenerationForm';
import BDDTransferForm from 'src/components/init-bdd/BDDTransferForm';
import BDDRecoveryForm from 'src/components/init-bdd/BDDRecoveryForm';
import {
  deleteScheduleInitBDDDatabase,
  generateInitBDDDatabase,
  getInitBDDConfig,
  getInitBDDStatus,
  listBackupInitBDDDatabase,
  listScheduledInitBDDDatabase,
  restoreBackupInitBDDDatabase,
  transferInitBDDDatabase
} from 'src/services/init-bdd.service';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import BDDGenerationScheduleForm from 'src/components/init-bdd/BDDGenerationScheduleForm';
import { isAutenticated } from '../services/login.service';

const BDDPage = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [statusData, setStatusData] = useState({ steps: [] });
  const [backups, setBackups] = useState([]);
  const [scheduledProcesses, setScheduledProcesses] = useState([]);

  const [onGoing, setOnGoing] = useState(false);
  const [transferEnabled, setTransferEnabled] = useState(false);
  const [generateEnabled, setGenerateEnabled] = useState(false);
  const [restoreEnabled, setRestoreEnabled] = useState(false);
  const [transferDestinations, setTransferDestinations] = useState([]);

  const [generateVisible, setGenerateVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [restoreVisible, setRestoreVisible] = useState(false);

  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

  const handleScheduleDialogClose = () => {
    setOpenScheduleDialog(false);
  };

  const handleScheduleDialogOpen = () => {
    setOpenScheduleDialog(true);
  };

  /**
   * Handle change tab page
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (!isAutenticated()) {
      navigate('/login');
    }
  }, []);

  const loadScheduledProcesses = () => {
    listScheduledInitBDDDatabase().then((scheduledProcessesResponse) => {
      const formattedScheduledProcesses = scheduledProcessesResponse.data.map((scheduledProcess) => {
        return {
          id: scheduledProcess.id,
          scheduledAt: DateTime.fromISO(scheduledProcess.scheduledAt).toFormat('dd/MM/yyyy HH:mm:ss'),
          user: scheduledProcess.user
        };
      });

      setScheduledProcesses(formattedScheduledProcesses);
    });
  };

  /**
   * Show generate tab page
   */
  const showGenerate = () => {
    loadScheduledProcesses();

    setGenerateVisible(true);
    setTransferVisible(false);
    setRestoreVisible(false);
  };

  const loadBackups = () => {
    listBackupInitBDDDatabase()
      .then((backupsResponse) => {
        setBackups(backupsResponse.data);
      })
      .catch((error) => {
        toast.error('Impossible de récupérer les sauvegardes de la BDD');
      });
  };

  /**
   * Show restore tab page
   */
  const showRestore = () => {
    loadBackups();

    setGenerateVisible(false);
    setTransferVisible(false);
    setRestoreVisible(true);
  };

  /**
   * Show transfer tab page
   */
  const showTransfer = () => {
    setGenerateVisible(false);
    setTransferVisible(true);
    setRestoreVisible(false);
  };

  /**
   * Get init bdd config for current environment,
   * and display the first available tab page
   */
  const getConfig = () => {
    getInitBDDConfig().then((configResponse) => {
      setGenerateEnabled(configResponse.data.generateEnabled);
      setTransferEnabled(configResponse.data.transferEnabled);
      setRestoreEnabled(configResponse.data.restoreEnabled);
      setTransferDestinations(configResponse.data.destinations);

      if (!configResponse.data.generateEnabled) {
        if (!configResponse.data.transferEnabled) {
          // Restore tab pag is the default and first one, load backups
          showRestore();
        } else {
          showTransfer();
        }
      } else {
        showGenerate();
      }
    });
  };

  /**
   * Check last init bdd process status
   * @returns
   */
  const getStatusData = async () => {
    let statusResponse;

    try {
      statusResponse = await getInitBDDStatus();
    } catch (error) {
      toast.error('Impossible de récupérer le statut de Init BDD');
      return;
    }

    const formattedSteps = statusResponse.data.steps.map((step, index) => {
      return {
        id: index,
        label: step.label,
        time: DateTime.fromISO(step.time).toFormat('dd/MM/yyyy HH:mm:ss')
      };
    });

    setOnGoing(statusResponse.data.status === 'ACTIVE');

    setStatusData({
      ...statusResponse.data,
      steps: formattedSteps
    });
  };

  /**
   * On page open
   */
  useEffect(() => {
    getStatusData();
    getConfig();

    const intervalCall = setInterval(() => {
      getStatusData();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  /**
   * Call Init BDD process
   *
   */
  const handleGenerate = async () => {
    generateInitBDDDatabase().catch((error) => {
      toast.error('Impossible de lancer la génération de la BDD');
    });

    setOnGoing(true);

    getStatusData();
  };

  /**
   * Call Init BDD restoration proces
   */
  const handleRestore = async (backupId) => {
    restoreBackupInitBDDDatabase(backupId).catch((error) => {
      toast.error('Impossible de lancer la restauration de la BDD');
    });

    getStatusData();

    setOnGoing(true);
  };

  /**
   * Delete schedule Init BDD process
   *
   */
  const handleDeleteSchedule = async (id) => {
    deleteScheduleInitBDDDatabase(id)
      .catch((error) => {
        toast.error('Impossible de supprimer la planification de la BDD');
      })
      .then(() => {
        loadScheduledProcesses();
      });
  };
  /**
   * Call Init BDD transfer process
   *
   * @param {*} envSource
   * @param {*} envSourceInput
   * @param {*} envTarget
   */
  const handleTransfer = async (envTarget) => {
    transferInitBDDDatabase(envTarget).catch((error) => {
      toast.error('Impossible de lancer le transfert de la BDD');
    });

    getStatusData();

    setOnGoing(true);
  };

  /**
   * Get action label from action value
   *
   * @param {*} action
   * @returns
   */
  const getAction = () => {
    let actionLabel = '';

    switch (statusData.action) {
      case 'GENERATE_AUTO':
        actionLabel = 'Génération automatique de la base de données';
        break;
      case 'GENERATE_MANUAL':
        actionLabel = 'Génération manuelle de la base de données';
        break;
      case 'TRANSFER':
        actionLabel = 'Transfert de la base de données';
        break;
      case 'RESTORE':
        actionLabel = 'Restauration de la base de données';
        break;
      default:
        actionLabel = `Unknown action ${statusData.action}`;
    }

    if (statusData.status === 'ACTIVE') {
      return actionLabel;
    }

    const actionDate = DateTime.fromISO(statusData.createdAt).toFormat('dd/MM/yyyy');
    const actionTime = DateTime.fromISO(statusData.createdAt).toFormat('HH:mm');

    return `${actionLabel} par ${statusData.user} le ${actionDate} à ${actionTime}`;
  };

  /**
   * Get steps table title
   *
   * @returns
   */
  const getTitle = () => {
    const status = statusData.status === 'ACTIVE' ? 'Opération en cours' : 'Dernière opération';

    return status;
  };

  /**
   * JSX
   */
  return (
    <>
      <Helmet>
        <title>RTM | Init BDD</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 11
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} xs={12}>
              <Tabs value={tabIndex} onChange={handleChange}>
                {generateEnabled && <Tab label="Génération" onClick={showGenerate} />}
                {transferEnabled && <Tab label="Transfert" onClick={showTransfer} />}
                {restoreEnabled && <Tab label="Restauration" onClick={showRestore} />}
              </Tabs>

              {generateEnabled && (
                <div role="tabpanel" hidden={!generateVisible}>
                  <BDDGenerationForm
                    onGoing={onGoing}
                    handleScheduleOpenDialog={handleScheduleDialogOpen}
                    scheduledProcesses={scheduledProcesses}
                    handleGenerate={handleGenerate}
                    handleDeleteSchedule={handleDeleteSchedule}
                  />
                </div>
              )}

              {transferEnabled && (
                <div role="tabpanel" hidden={!transferVisible}>
                  <BDDTransferForm
                    onGoing={onGoing}
                    transferDestinations={transferDestinations}
                    handleTransfer={handleTransfer}
                  />
                </div>
              )}

              {restoreEnabled && (
                <div role="tabpanel" hidden={!restoreVisible}>
                  <BDDRecoveryForm onGoing={onGoing} handleRestore={handleRestore} backups={backups} />
                </div>
              )}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            />

            <Grid item lg={8} md={12} xs={12}>
              {statusData && (
                <Card>
                  <div className="card-header-log">
                    <CardHeader title={getTitle()} subheader={getAction()} />
                    {onGoing && <CircularProgress />}
                  </div>
                  <Divider />
                  <CardContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tablecell-green">Etape</TableCell>
                          <TableCell className="tablecell-green">Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody md={6} xs={12}>
                        {statusData.steps.map((data) => {
                          if (data.label.indexOf('ERROR') > 0) {
                            return (
                              <TableRow key={data.id}>
                                <TableCell sx={{ color: '#FF0000' }}>{data.label}</TableCell>
                                <TableCell sx={{ color: '#FF0000' }}>{data.time}</TableCell>
                              </TableRow>
                            );
                          }

                          return (
                            <TableRow key={data.id}>
                              <TableCell>{data.label}</TableCell>
                              <TableCell>{data.time}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog open={openScheduleDialog} onClose={handleScheduleDialogClose} maxWidth={false}>
        <BDDGenerationScheduleForm
          onClose={(refresh) => {
            handleScheduleDialogClose();

            if (refresh) {
              loadScheduledProcesses();
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default BDDPage;
